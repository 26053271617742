table.table {
  @include font-size(18px);
  border-collapse: collapse;
  background-color: $ws-bg-white;

  th, td {
    padding: 4px 10px;
    text-align: left;
    vertical-align: top;
  }

  thead {
    background-color: $ws-bg-lightblue;
    color: $ws-txt-white;

    th {
      @extend .font-bold;
      text-align: left;
    }
  }

  tbody {
    @extend .font-light;

    tr {
      td {
        &.date {
          width: 250px;
          @extend .font-medium;
          color: $ws-txt-lightblue;
        }
      }

      &:nth-child(even) {
        background-color: lighten($ws-bg-lightestblue, 1%);
      }
    }
  }

  @media only screen and (max-width: $screen-xs-max) {

    tbody {
      tr {
        display: block;
        padding-bottom: 10px;

        td {
          padding: 2px 10px;
        }
      }
    }

    th, td {
      display: block;
      border: 0 !important;

      label {
        display: inline-block !important;
      }
    }
  }
}