.block {
    text-align: left;
    width: calc(33%);
    min-width: 300px;
    flex: 0 1 calc(33%);
    padding: 20px;
    display: block;

    &:last-child {
        margin-right: 0;
    }

    &__wrapper {
        display: block;

        .link-as-wrapper {
            text-decoration: none;
        }
    }

    &__header {
        .block__image img {
            border-radius: 5px;
            margin-bottom: 15px;
            max-width: 100%;
            border: none;
        }
    }

    &__date {
        color: $ws-txt-lightblue;
        @include font-size(16px);
        @extend .font-medium;
    }

    &__title {
        @extend .font-medium;
        @include font-size(24px);
        color: $ws-txt-lightblue;
        text-transform: none;
    }

    &__text {
        @extend .font-light;
        @include font-size(18px);
    }

    &__button {
        display: inline-block;
    }

    @media only screen and (max-width: $screen-xs) {
        width: 100%;
        padding: 0;
        margin: 15px 0 30px 0;

        &__text {
            margin-top: 0;
        }

        &__header {
            .block__image img {
                margin-bottom: 0;
            }
        }
    }

    @media only screen and (max-height: $screen-xxs) and (orientation: landscape) {
        width: 100%;
        max-width: 100%;

        &__wrapper {
            width: 100%;
        }

        &__header {
            .block__image img {
                width: 100%;
            }
        }
    }
}