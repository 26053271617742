$cb-size: 12px;

input[type="checkbox"].form-checkbox {
    position: absolute;
    left: -9999px;
    display: initial;
    width: auto;

    &+label {
        @include font-size(14px);
        cursor: pointer;
        //margin-top: 5px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        &:hover {
            &:before {
                border: 1px solid $ws-bg-white;
                background: $ws-bg-white;
                color: $ws-border-lightgray;
            }
        }

        &:before {
            @include font-size(12px);

            padding: 4px;
            content: '\f00c';
            display: inline-block;
            height: $cb-size;
            width: $cb-size;
            line-height: $cb-size;
            text-align: center;
            border: 1px solid $ws-white;
            border-radius: $border-radius;
            background: $ws-bg-white;
            color: $ws-txt-white;
            font-family: 'icomoon';
            transition: all $transition-speed ease;
            cursor: pointer;
            position: absolute;
            top: auto;
            left: 0;

        }

        &.error {
            &:hover {
                &:before {
                    border: 1px solid inherit;
                }
            }

            &.shake {
                animation: shake 0.8s cubic-bezier(.36,.07,.19,.97) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                perspective: 1000px;
            }

            &:before {
                border: 1px solid $ws-red;
                color: $ws-txt-white;
            }
        }

        span {
            display: inline-block;
            max-width: 100%;
            line-height: 150%;
            margin-left: 36px;
            text-align: left;

            a {
                text-decoration: underline;
            }
        }
    }

    &:checked {
        &+label {
            &:before {
                border: 1px solid $ws-bg-white;
                background: $ws-bg-white;
                color: $ws-green;
                animation: pulse 0.8s;
            }
        }
    }
}


@media only screen and (max-width: $screen-xs-max) {
    input[type="checkbox"].form-checkbox {
        &+label {
            &:hover {
                &:before {
                    color: $ws-txt-white;
                }
            }

            &:before {
                border: 1px solid $ws-bg-white;
                background: $ws-bg-white;
                color: $ws-txt-white;
            }

            &.error {
                &:hover {
                    &:before {
                        border: 1px solid inherit;
                    }
                }

                &:before {
                    border: 1px solid $ws-txt-red;
                }
            }
        }

        &:checked {
            &+label {
                &:before {
                    border: 1px solid $ws-txt-white;
                    background: $ws-bg-white;
                    color: $ws-green;
                    animation: pulse 0.8s;
                }
            }
        }
    }
}
