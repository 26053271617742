section.gallery {
    .gallery-images {
        margin: 0 0 20px 0px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex: 0 1 auto;
        max-width: 100%;

        .thumb {
            display: flex;
            flex: 0 0 calc((100% / 4) - 20px);
            margin: 10px 20px 10px 0px;
            position: relative;
            text-decoration: none;

            img {
                max-width: 280px;
                width: 100%;
                height: 100%;
                border: none;
            }

            &:after {
                @include font-size(14px);
                @extend .font-book;
                text-align: center;
                text-decoration: none;
                line-height: 100%;
                opacity: 0;
                padding: 10px;
                visibility: invisible;
                content: attr(data-caption);
                color: $ws-txt-white;
                position: absolute;
                left: 0;
                bottom: 0;
                height: auto;
                width: calc(100% - 20px);
                background-color: rgba($ws-bg-darkblue, 0.9);
                transition: opacity 0.2s ease;
            }

            &:hover {
                img {
                    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .2);
                }

                &:after {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    @media only screen and (max-width: $screen-xs) {
        .gallery-images {
            justify-content: space-between;

            .thumb {
                flex: 0 0 calc(50% - 10px);
                margin: 0 0 15px 0;
            }
        }
    }
}