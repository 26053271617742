.container {
    margin: 0 auto;

    /* Tiny */
    @media only screen and (max-width : $screen-xxs) {
        width: 100%;

        .row {
            flex-direction: column;
            margin: 0;
        }
    }

    /* Custom, iPhone Retina */
    @media only screen and (min-width : $screen-xxs) {
        width: 100%;

        .row {
            flex-direction: column;
            margin: 0;
        }
    }

    /* Extra Small Devices, Phones */
    @media only screen and (min-width : $screen-xs) {
        width: 100%;

        .row {
            flex-direction: column;
        }
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width : $screen-sm) {
        width: 750px;
    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width : $screen-md) {
        width: 970px;
        .row {
            flex-direction: row;
        }
    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : $screen-lg) {
        width: 1170px;
    }
}

.row {
    display: flex;
    padding: 0;
    width: 100%;
    margin: 0;

    .column {
        margin: 0 $element-padding 0 0;
        width: 100%;

        &.flex-1 {
            flex: 1;
        }
        &.flex-2 {
            flex: 2;
        }
        &.flex-3 {
            flex: 3;
        }
        &.flex-4 {
            flex: 4;
        }
        &.flex-5 {
            flex: 5;
        }
        &.flex-6 {
            flex: 6;
        }
        &.flex-7 {
            flex: 7;
        }
        &.flex-8 {
            flex: 8;
        }
        &.flex-9 {
            flex: 9;
        }
        &.flex-10 {
            flex: 10;
        }
        &.flex-11 {
            flex: 11;
        }
        &.flex-12 {
            flex: 12;
        }

        &.w-33 {
            width: calc(100% / 3);
            flex: none;
        }

        &.w-50 {
            width: 50%;
            flex: none;
        }

        &.w-66 {
            width: 66%;
            flex: none;
        }

        &.center {
            text-align: center;
        }

        @media only screen and (max-width: $screen-sm) {
            &.w-33, &.w-50, &.w-66 {
                width: 100%;
            }
        }
    }

    >:last-child, &.last {
        margin-right: 0 !important;
    }
}

&.center {
    .row {
        justify-content: center;
        align-items: center;
    }
}
