.area.news-overview {
    .pagination {
        margin: 0 0 30px 0;
    }

    .area__components {
        display: block;

        .block {
            width: 100%;
            flex: 0 1 auto;
            max-width: none;
            border-top: 1px solid $ws-border-lightgray;
            padding: 30px 0;
            margin-right: 0;
            margin-bottom: 0;

            .block__wrapper {
                width: 100%;
                flex: 0 1 auto;

                .link-as-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    flex: 0 1 auto;

                    .block__header {
                        order: 2;
                        margin-left: 30px;

                        img {
                            flex: 0 0 auto;
                            width: 360px;
                            height: 240px;
                            max-width: none;
                            margin-bottom: 0;
                        }
                    }

                    .block__content {
                        order: 1;
                        flex: 0 1 auto;
                    }
                }
            }

        }

        @media only screen and (max-width: $screen-sm) and (orientation: portrait) {
            .block {
                .block__wrapper {
                    .link-as-wrapper {
                        .block__header {
                            display: none;
                        }
                        display: block;
                    }
                }
            }
        }

        @media
        only screen and (max-width: $screen-xs) and (orientation: portrait),
        only screen and (max-width: $screen-xxs) and (orientation: portrait) {
            .block {
                .block__wrapper {
                    .link-as-wrapper {
                        .block__header {
                            display: block;
                            margin-left: 0;

                            img {
                                max-width: 100%;
                            }
                        }
                        display: block;
                    }
                }
            }
        }

        &+ .pagination {
            padding-top: 30px;
            border-top: 1px solid $ws-border-lightgray;
        }
    }
}