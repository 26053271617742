form {

    .form-group-spacer {
        height: $element-padding;

        &.double {
            height: $element-padding * 2;
        }
    }

    .form-grouped {
        display: flex;
        flex-direction: row;

        @media (max-width: $screen-xs-max) {
            display: block;
            flex: initial;
        }

        .form-group-inline {
            flex: 1;
        }

        .form-group-inline + .form-group-inline {
            margin-left: 20px;

            @media (max-width: $screen-xs-max) {
                margin-left: 0;
            }
        }
    }


    .form-group-inline {
        width: 100%;
        display: block;
        align-items: center;
        margin: 5px 0 15px 0;
        position: relative;

        &.no-margin-top {
            margin-top: 0;
        }

        &.submit {
            margin-bottom: 0;
        }

        @media only screen and (max-width: $screen-xs-max) {
            display: block;
            flex: initial;

            div[class^='flex-'] {
                width: 100%;
                position: relative;
            }
        }

        input {
            width: 100%;

            &[type=submit] {
                width: auto;
            }
        }


        label {
            .txt-warmred {
                @include font-size(16px);
                margin-right: $element-padding / 3;
            }
            .txt-lightblue {
                @include font-size(16px);
                margin-right: $element-padding / 3;
            }
            .txt-white {
                @include font-size(16px);
                margin-right: $element-padding / 3;

                @media only screen and (max-width: $screen-xs-max) {
                    margin-right: 0;
                    display: none;
                }
            }
        }
    }

    .form-group {
        width: 100%;

        input + .btn {
            border-radius: 0 3px 3px 0 !important;
            margin-left: -10px;
            margin-bottom: 0;
            position: relative;
            z-index: 1;
        }
    }

    .message {
        transform: translateY(0px);
        opacity: 0;
        visibility: hidden;
        margin: 15px 0;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        line-height: 120%;
        @include font-size(14px);
        transition: all 0.2s ease;
        text-align: left;

        &.error, &.success, &.warning {
            opacity: 1;
            visibility: visible;
            transform: translateY(5px);
        }

        &:before {
            content: "";
            font-family: 'icomoon';
            margin: 3px 15px 0 0;
            @include font-size(30px);
        }

        &.error {
            &:before {
                content: "\f00d";
                color: $ws-txt-red;
            }
        }

        &.success {
            &:before {
                content: "\f00c";
                color: $ws-green;
            }
        }

        &.warning {
            &:before {
                content: "\f129";
                color: orange;
            }
        }
    }
}



#form-subscribe {
    max-width: 300px;
}

#form-contact {
    max-width: 760px;
    margin: 0 auto;

    textarea.medium {
        @media only screen and (min-width: $screen-xs) {
            height: 100px;
        }
    }

    .message {
        justify-content: center;
        margin: 0;

        &.error {
            margin: 0 0 20px 0;
        }

        &.success {
            margin: 20px 0;
        }
    }
}
