section.video {
    &.lightestblue {
        color: $ws-txt-lightblue;
    }

    text-align: center;

    h2 {
        margin-bottom: 30px;
    }

    .video-container {
        span {
            display: none;
        }


        @media only screen and (max-width: $screen-xs) {
            iframe {
                width: 100%;
            }
        }
    }

}