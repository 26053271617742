form {
    input {
        @include font-size(14px);
        @extend .font-book;
        background: $ws-bg-white;
        border: 1px solid $ws-bg-white;
        border-radius: $border-radius;
        -webkit-appearance: none;
        padding: 12px;
        color: $ws-txt-black;
        height: $input-height;
        line-height: 100%;
        outline: 0 !important;
        margin-right: 5px;
        position: relative;
        display: block;

        &.validate-field {
            padding: 12px 32px 12px 12px;
        }

        &[type='file'] {
            padding-left: 0;
        }

        &[type='password'] {
            padding-right: 100px;

            &.repeat {
                padding-right: 12px !important;
            }
        }

        // Honey pots
        &[name='hp'], &[name='le'] {
            position: absolute;
            left: -99999px;
        }

        // IE clear and reveal field icons
        &::-ms-clear,
        &::-ms-reveal {
            display: none !important;
        }

        &[readonly] {
            background: lightgray;
            border: none;

            &:focus, &:active, &:hover {
                border: none;
            }
        }

        &.error {
            border-color: $ws-bg-red;
            color: $ws-txt-red;

            &:focus, &:hover {
                border-color: $ws-txt-red;
                color: $ws-txt-red;
            }

            &.shake {
                animation: shake 0.8s cubic-bezier(.36,.07,.19,.97) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                perspective: 1000px;
            }
        }

        &::placeholder {
            color: $ws-border-gray;
        }
    }


    .input-wrapper {
        position: relative;
        width: 100%;

        &:after {
            font-family: 'icomoon';
            @include font-size(16px);
            content: "";
            position: absolute;
            right: 12px;
            top: 2px;
            opacity: 0;
            visibility: hidden;
            transition: top 0.1s ease;
        }

        &.active {
            &:after {
                content: attr(data-placeholder);
                position: absolute;
                left: 14px;
                top: 6px;
                color: #aaa;
                opacity: 1;
                visibility: visible;
            }

            input {
                padding-bottom: 1px;

                &::-ms-clear {
                    width: 0;
                    height: 0;
                }
            }
        }

        &.error {
            &:after {
                color: $ws-bg-red;
                content: "\f00d";
                top: 5px;
                opacity: 1;
                visibility: visible;
            }
            &.shake {
                animation: shake 0.8s cubic-bezier(.36,.07,.19,.97) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                perspective: 1000px;
            }
        }
    }



    @media only screen and (max-width: $screen-xs-max) {
        input {
            @include font-size(16px);
            padding-right: 12px !important;

            &[type='password'] {
                padding-right: 100px !important;
            }
        }

        label.error:after,
        label.valid:after,
        .message {
            //display: none !important;
        }
    }
}