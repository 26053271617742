section.branch {

    .branch-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .branch-info {
            flex: 5;
            padding-right: 30px;

            @include font-size(18px);
            @extend .font-light;

            h4 {
                margin-bottom: 30px;
            }

            strong, table .job-title {
                color: $ws-txt-lightblue;
                @extend .font-bold;
            }

            .contact {
                margin-top: 20px;

                i {
                    margin-right: 5px;
                    color: $ws-txt-lightblue;
                    text-decoration: none;
                }

                a {
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }

            }
        }
        .branch-map {
            flex: 6;
            border-radius: 5px;
            width: 100%;
            height: 350px;
        }

        @media only screen and (max-width: $screen-xs),
        only screen and (max-width: $screen-xs-max) and (orientation: landscape){
            display: block;

            .branch-info {
                margin-bottom: 30px;
            }
        }
    }
}