header {
    width: 100%;
    padding: 0;
    background: $ws-bg-white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.3);
    display: flex;

    @media only screen and (max-width: $screen-sm) {
        padding: 10px;
        position: fixed;
        background: $ws-bg-white;
        z-index: 999;
        top: 0;
        height: 79px;
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .toggle-wrapper {
            background-color: $ws-bg-white;
            width: 0%;
            z-index: 9999;
            position: fixed;
            left: 0;
            top: 0;
            height: 60px;
            transition: width 0.2s ease;

            @media only screen and (max-width: $screen-sm) {
                display: block;
                width: 60px;
            }
        }

        .toggle-nav {
            background: transparent;
            border: none;
            height: 30px;
            width: 30px;
            padding: 0;
            outline: none;
            cursor: pointer;
            position: fixed;
            top: 10px;
            left: 10px;
            z-index: 9998;
            cursor: pointer;

            &:focus, &:active {
                outline: none;
            }

            .line {
                width: 100%;
                height: 2px;
                border-radius: 2px;
                display: block;
                background-color: $ws-txt-gray;
                transition: all 0.2s ease;
                position: absolute;

                &.l1 {
                    transform: rotate(0);
                    transform-origin: left top;
                    top: 4px;
                }

                &.l2 {
                    opacity: 1;
                    top: 13px;
                }

                &.l3 {
                    transform: rotate(0);
                    bottom: 5px;
                    transform-origin: left bottom;
                }
            }

            &.expand {
                .l1, .l3 {
                    width: 34px;
                }

                .l1 {
                    transform: rotate(45deg);
                    top: 2px;
                }

                .l2 {
                    border-bottom: 1px solid black;
                    opacity: 0;
                }

                .l3 {
                    transform: rotate(-45deg);
                    bottom: 2px;
                }
            }
        }

        .logo {
            img {
                height: 59px;
                display: block;
                border: none;
            }
        }

        @media only screen and (max-width: $screen-sm) {
            justify-content: flex-end;
        }
    }

    &.nav-open {
        .container {
            .toggle-wrapper {
                width: 90%;
            }
        }
    }


    .nav-underlay {
        background: rgba(0,0,0,0.2);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: none;
        cursor: pointer;
    }

    &.nav-open {
        .nav-underlay {
            display: block;
        }

        nav.navigation {
            transform: translateX(0%);
        }
    }

}