hr {
	border: 0;
	border-bottom: 1px solid $ws-txt-gray;
	margin: $element-padding 0;
	display: inline-block;
	width: 100%;
	
	&+p {
		margin-top: 0;
	}
	
	@media only screen and (max-width: $screen-xs-max) {
		margin: ($element-padding / 2) 0;
	}
}