html {
    font-size: 16px;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    @include font-size(16px);
    @extend .font-book;
    background-color: $ws-white;
    font-style: normal;
    line-height: 30px;
    margin: 0;
    padding: 0;
    color: $ws-txt-black;

    @media only screen and (max-width : $screen-xs-max) {
        @include font-size(16px);
    }
}

.hidden-all {
    display: none !important;
}

.hidden-xs {
    @media only screen and (max-width : $screen-xs) {
        display: none !important;
    }
}

.hidden-xs-max {
    @media only screen and (max-width : $screen-xs-max) {
        display: none !important;
    }
}


/* hide on tablet portrait */
.hidden-tp {
    @media only screen and (width: $screen-sm) and (orientation: portrait) {
        display: none !important;
    }
}

.hidden-sm {
    @media only screen and (max-width : $screen-sm) {
        display: none !important;
    }
}

.hidden-sm-max {
    @media only screen and (max-width : $screen-sm-max) {
        display: none !important;
    }
}

.visible-xs {
    @media only screen and (max-width : $screen-xs) {
        display: block !important;
    }

    @media only screen and (min-width : $screen-xs) {
        display: none !important;
    }
}

.visible-sm {
    @media only screen and (max-width : $screen-sm) {
        display: block !important;
    }

    @media only screen and (min-width : 769px) {
        display: none !important;
    }
}

.hidden-xs-landscape {
    @media only screen and (max-width: $screen-xs-max) and (orientation: landscape) {
        display: none !important;
    }
}

.visible-xs-landscape {
    @media only screen and (max-width: $screen-xs-max) {
        display: block !important;
    }
    @media only screen and (min-width: $screen-sm) {
        display: none !important;
    }
}


@media screen and (max-device-width: $screen-xs){
    body {
        -webkit-text-size-adjust: none;
    }
}