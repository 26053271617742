// Global CMS bar fixes
.mgnlEditorBar,
.mgnlPlaceholder {
    min-width: 200px;
}

footer {
    &.mgnEditMode {
        .wrapper {
            &.bg-darkblue {
                .links {
                    flex-direction: column;
                    align-items: center;

                    .mgnlEditor {
                        width: 300px !important;
                        margin: 0 auto !important;

                        &.area {
                            margin-top: 20px !important;
                        }
                    }

                    li {
                        &:after {
                            content: "";
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}