section {
	padding: $section-padding 0;

	&.red {
		background-color: $ws-bg-red;
		color: $ws-txt-white;

		a {
			color: $ws-txt-white;
		}

		.contact {
			a {
				color: $ws-txt-white;
			}
		}
	}

	&.lightestblue {
		background-color: $ws-bg-lightestblue;
		color: $ws-txt-gray;

		h2, .contact {
			color: $ws-txt-lightblue;

			a {
				color: $ws-txt-lightblue;
			}
		}
	}

	&.lightblue {
		background-color: $ws-bg-lightblue;
		color: $ws-txt-white;

		h2, a {
			color: $ws-txt-white;
		}

		.text-section {
			color: $ws-txt-white;
		}
	}

	&.white {
		background-color: $ws-bg-white;

		h2, .contact {
			color: $ws-txt-lightblue;

			a {
				color: $ws-txt-lightblue;
			}
		}
	}

	@media only screen and (max-width : $screen-xs-max) {
		.container {
			padding: 0 10px;
		}
	}

	@media
	only screen and (max-width : $screen-xs),
	only screen and (max-height: $screen-xxs) and (orientation: landscape) {
		padding: 30px 0;
	}

	.intro {
		@extend .font-medium;
		@include font-size(18px);
		line-height: 167%;

		@media only screen and (max-width: $screen-xs-max) {
			br {
				display: inline;
				content: ' ';
				margin-right: 5px;
			}
		}
	}
}