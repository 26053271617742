section.featured-pages {

    .area__title {
        @media only screen and (max-width: $screen-xs),
        only screen and (max-height: $screen-xxs) and (orientation: landscape) {
            margin-bottom: 30px;
        }
    }

    .featured-block {
        height: 280px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 40px;
        background-size: cover;
        background-position: center center;
        border-radius: 5px;
        flex: 1;
        box-shadow: none;
        transition: box-shadow 0.2s ease;
        position: relative;
        text-decoration: none;

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba($ws-bg-lightblue, 0.7);
            border-radius: 5px;
            z-index: 1;
            opacity: 1;
            transition: opacity 0.2s ease;
        }

        h3 {
            @include font-size(36px);
            text-shadow: 0 0 30px #000;
            color: $ws-txt-white;
            position: relative;
            z-index: 2;
            line-height: 120%;

            @media only screen and (max-width: $screen-xs) {
                @include font-size(30px);
            }
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            box-shadow: 0 10px 20px 0 rgba(0,0,0,.2);
            text-decoration: none;

            &:before {
                opacity: 0;
            }
        }

        @media only screen and (max-width: $screen-sm) {
            height: 150px;
            margin-right: 20px;
        }

        @media only screen and (max-width: $screen-xs), only screen and (max-height: $screen-xxs) and (orientation: landscape) {
            margin-right: 0;
            margin-bottom: 15px;
        }

        @media only screen and (max-height: $screen-xxs) and (orientation: landscape) {

        }
    }
}