@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%, 80% {
		transform: translate3d(2px, 0, 0);
	}

	30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%, 60% {
		transform: translate3d(4px, 0, 0);
	}
}


@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba($ws-green, 0.6);
	}
	70% {
		box-shadow: 0 0 0 10px rgba($ws-green, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba($ws-green, 0);
	}
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    30%, 50% {
        transform: translateY(10px);
    }
}