.search-results {
	.container {
		text-align: center;

		.area__container {
			text-align: center;

			form {
				max-width: 400px;
				margin: 0 auto;

				.form-group-inline {
					display: flex;
					flex-direction: row;

					input[type=search] {
						@include font-size(18px);
						border: 1px solid $ws-border-gray;
						flex: 5;
						height: 40px;
					}

					button {
						flex: 1;
						height: 40px;
					}
				}
			}

			.search-feedback {
				margin: 30px 0;
			}
		}
	}
}