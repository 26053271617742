/* Mixins */
@mixin btn-solid($bgcolor, $txtcolor, $bgcolorhover) {
    @include border-radius($border-radius);
    @include font-size(16px);
    background-color: $bgcolor;
    border-bottom: 2px solid darken($bgcolor, 10%);
    color: $txtcolor;
    border-radius: $border-radius;

    &:hover {
        background-color: $bgcolorhover;
        border-color: $bgcolorhover;
    }

    &:focus {
        outline: none !important;
    }

    &::-moz-focus-inner {
        border: 0;
    }
}

.btn {
    @include font-size(18px);
    @extend .font-bold;
    padding: 8px 32px;
    text-decoration: none !important;
    transition: all $transition-speed ease;
    cursor: pointer;
    display: inline-block;
    min-height: $input-height - 2;
    //line-height: $input-height - 2;
    line-height: 120%;
    box-sizing: border-box;

    &.ws-lightblue {
        @include btn-solid($ws-lightblue, $ws-txt-white, darken($ws-lightblue, 5%));
    }

    &.ws-blue {
        @include btn-solid($ws-darkblue, $ws-txt-white, darken($ws-darkblue, 5%));
    }

    &.ws-green {
        @include btn-solid($ws-green, $ws-txt-white, #73be5a);
    }

}

button, .btn {
    outline: none !important;
}

button {
    border: none;
}