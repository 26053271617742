section.text-image {
	.container {
        text-align: left;

		.content {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			text-align: left;
			flex: 0 1 auto;
			@extend .font-light;

			&.img-left {
				img {
					order: 0;
					margin-right: 30px;
				}

				.text-section {
					order: 1;
				}
			}

			&.img-right {
				img {
					order: 1;
					margin-left: 30px;
				}

				.text-section {
					order: 0;
				}
			}

			img {
				margin-top: 24px;
				width: 400px;
				height: 100%;
				flex-shrink: 0;
			}

			.text-section {
				flex: 0 1 auto;

				&.two-columns {
					columns: 2;
					column-gap: 40px;
					margin-top: 30px;

					p {
						margin-top: 0;
					}

					@media only screen and (max-width: $screen-xs) {
						columns: auto;
						margin-top: 0;
					}
				}

				.contact {
					@extend .font-book;
					margin-top: 30px;

					.contact-name {
						@extend .font-bold;
					}

					i {
						margin-right: 10px;
					}

					a {
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}

				@media only screen and (max-width: $screen-xs) {
					p {
						margin-top: 0;
					}
				}
			}


			@media only screen and (max-width: $screen-xs), (max-width: $screen-xs-max) and (orientation: landscape) {
				flex-direction: column;

				.text-section {
					order: 1 !important;
				}

				img {
					margin: 0 !important;
					width: 100%;
					order: 0 !important;
				}
			}
		}

		&.center {
			text-align: center;

			.content {
				text-align: center;
				justify-content: center;
			}
		}
	}

	&.red, &.lightblue {
		.content {
			@extend .font-book;
		}
	}

	@media only screen and (max-width: $screen-xs) {
		h2 {
			margin-bottom: 15px;
		}
	}
}