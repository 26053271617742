section.calendar {
    .area__components {
        justify-content: center;
    }

    table {
        width: 75%;

        @media only screen and (max-width: $screen-xs) {
            width: 100%;

            thead {
                display: none;
            }
        }
    }

}