$featureSize: 400px;

.text-image-featured {
	padding: 0;

	.container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		position: relative;
		min-height: $featureSize - ($section-padding * 2);

		.featured-text {
			padding: $section-padding 0;
			@extend .font-medium;
			@include font-size(18px);
			display: block;
		}

		.featured-image {
			height: $featureSize;
			width: $featureSize;
			border: 5px solid $ws-white;
			border-radius: 50%;
			right: 10px;
			position: absolute;
		}

		@media only screen and (min-width: $screen-sm) {
			.featured-text {
				width: calc(100% - #{$featureSize + 50px});
			}

			.featured-image {
				top: calc((100% - #{$featureSize}) / 2);
			}
		}

		@media only screen and (max-width: $screen-sm) {
			.featured-text {
				width: calc(100% - 350px);
			}

			.featured-image {
				height: 300px;
				width: 300px;
				top: calc((100% - 300px) / 2);
			}
		}

		@media only screen and (max-width: $screen-xs) {
			flex-direction: column;
			align-items: center;

			.featured-text {
				order: 1;
				width: 100%;
				text-align: center;
				padding: 30px 0;
			}

			.featured-image {
				position: relative;
				top: -15px;
				right: 0;
				order: 0;
			}
		}
	}
}