.contact-form {
	.container {
		text-align: center;
	}
}

#contact-agreement, #subscribe-agreement {
	display: none;
	max-width: 80%;
	max-height: 80%;

	.fancybox-close {
		float: right;
	}

	.fancybox-close-small {
		outline: none !important;

		&:after {
			outline: none !important;
		}
	}
}