.area {
    &__wrapper {
        text-align: center;

        .link-as-wrapper {
            &:hover {
                text-decoration: none;
                color: inherit;
            }
        }

        .block__button {
            margin: 0 auto 0 0;
        }
    }

    &__title {
        @include font-size(36px);
        margin: 0 auto;
        margin-bottom: $section-padding;

        @media only screen and (max-width: $screen-xs) {
            margin-bottom: 0px;
            @include font-size(30px);
        }
    }

    &__components {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;

        @media only screen and (max-width: $screen-xs),
        only screen and (max-height: $screen-xxs) and (orientation: landscape) {
            display: block;
        }
    }
}