form {
    textarea {
        @include font-size(14px);
        @extend .font-book;
        background: $ws-bg-white;
        border: 1px solid $ws-bg-white;
        border-radius: $border-radius;
        color: $ws-txt-black;
        outline: 0 !important;
        padding: 12px 45px 12px 12px;
        position: relative;
        transition: all $transition-speed ease;
        display: block;
        resize: none;
        width: 100%;

        &.medium {
            height: 150px;
        }

        &.error {
            border: 1px solid $ws-bg-red !important;
            color: $ws-txt-red;

            &:focus, &:hover {
                border-color: $ws-bg-red;
                color: $ws-txt-red;
            }

            &.shake {
                animation: shake 0.8s cubic-bezier(.36,.07,.19,.97) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                perspective: 1000px;
            }
        }

        &+label {
            &:after {
                top: 0 !important;
                right: 10px !important;
            }

            &+.message {
                margin-bottom: 0px;
            }
        }


        // IE clear field icon
        &::-ms-clear {
            display: none !important;
        }


        &[readonly] {
            background: lightgray;

            &:focus, &:active, &:hover {
                border: 1px solid lightgray;
            }
        }

        &::placeholder {
            color: $ws-border-gray;
        }
    }


    @media only screen and (max-width: $screen-xs-max) {

        textarea {
            @include font-size(16px);
        }
    }
}