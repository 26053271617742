section.download {
    &.lightestblue {
        color: $ws-txt-lightblue;
    }

    h2 {
        margin-bottom: 15px;
    }

    .intro {
        @include font-size(18px);
        @extend .font-medium;
        max-width: 750px;
        margin: 0 auto;
    }

    .btn {
        margin-top: 15px;
    }
}