@mixin transition($args) {
 	-webkit-transition: $args;
 	-moz-transition: $args;
 	-ms-transition: $args;
 	-o-transition: $args;
 	transition: $args;
}

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

@function calculateRem($size) {
	$remSize: $size / 16px;
	@return $remSize * 1rem;
}

@mixin col($flex) {
	flex: $flex;
}

@mixin border-radius($radius) {
	border-radius: $radius;
}


@mixin noListStyleType() {
	list-style-type: none;
	padding: 0;
	margin: 0;

	li {
		display: inline;
	}
}

@mixin transparent-color($color: #000, $value:0.5) {
	$start: "rgb(";
	$end: ")";
	background: #{$start}red($color), green($color), blue($color)#{$end};
	background: rgba($color, $value);
}

@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
}

@mixin font-size-i($size) {
	font-size: $size !important;
	font-size: calculateRem($size) !important;
}

// Horizontal gradient, from left to right
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.

@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Opera 12
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

// Vertical gradient, from top to bottom
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.

@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);  // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);  // Opera 12
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-repeat: repeat-x;
  background-image: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient($deg, $start-color, $end-color); // Opera 12
  background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}

@mixin font-uppercase() {
  text-transform: uppercase;
}

@mixin linearGradient($top, $bottom){
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
  background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}
