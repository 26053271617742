.pagination {
     &__list {
        @extend .nlst;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;

        &__item {

            &__link {
                @extend .font-header-medium;
                @include font-size(24px);
                color: $ws-txt-lightblue;
                text-decoration: none;
                padding: 0 15px;

                &:hover {
                    text-decoration: none;
                }

                &--disabled {
                    color: $ws-txt-lightblue2;
                }

                &--previous, &--next {
                    span {
                        display: none;
                    }
                    &:after {
                        font-family: "icomoon";
                    }
                }

                &--previous {
                    &:after {
                        content: "\f0a8";
                    }
                }
                &--next {
                    &:after {
                        content: "\f0a9";
                    }
                }
            }

            &--active {
                a {
                    @extend .font-header-semibold;
                    @include font-size(36px);
                    color: $ws-txt-red;
                    text-decoration: none;
                }
            }
        }
    }
}