footer {
	background-color: $ws-bg-darkblue;
	display: block;

	.wrapper {
		width: 100%;
		color: $ws-txt-white;

		.container {
			padding: 15px 0;
			color: $ws-txt-white;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			a {
				color: $ws-txt-white;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}

			.col {
				flex: 1;
				margin-right: 40px;
				position: relative;
				padding-top: 100px;

				&.flex-1 {
					flex: 1;
				}

				&.flex-2 {
					flex: 2;
				}

				&:last-child {
					margin-right: 0;
				}
			}

			.logo {
				position: absolute;
				top: 0;
				left: 0px;
				height: 70px;
			}
		}

		&.bg-blue {
			background-color: $ws-bg-blue;
			padding: $element-padding 0;

			.col-title {
				@extend .font-medium;
				@include font-size(18px);
			}

			.company-name {
				@include font-size(18px);
				@extend .font-medium;
			}

			.company-contact {
				margin-top: 30px;

				.contact-name {
					@extend .font-medium;
				}

				i {
					margin-right: 5px;
				}
			}

			ul {
				@extend .nlst;

				&.latest-news {
					li {
						padding: 8px 0;
						border-bottom: 1px solid $ws-border-lightblue;

						&:first-child {
							padding-top: 0;
						}

						.pub-date {
							@include font-size(14px);
						}
					}
				}
			}

			.subscribe {
				position: absolute;
				width: 100%;
				height: 180px;
				top: -100px;
				right: 0;

				.arrow {
					position: absolute;
					top: 90px;
					right: 184px;
					transition: all 0.2s ease;
				}

				.subscribe-now {
					background-color: $ws-green;
					border: 5px solid $ws-white;
					border-radius: 100%;
					color: $ws-txt-white;
					@extend .font-header-semibold;
					@include font-size(20px);
					text-transform: uppercase;
					line-height: 100%;
					text-align: center;
					width: 170px;
					height: 170px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					position: absolute;
					top: 0px;
					right: 0;
					box-shadow: none;
					transition: box-shadow 0.2s ease;

					span {
						display: block;
						padding: 0px;
						width: 100%;
					}
				}

				&:hover {
					.arrow {
						animation-name: bounce;
						animation-duration: 0.4s;
						animation-timing-function: ease-out;
						animation-iteration-count: 3;
					}

					.subscribe-now {
						box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.2);
					}
				}
			}
		}

		&.bg-darkblue {
			background-color: $ws-bg-darkblue;
			padding: 5px 0;

			.container {
				padding: 5px 0;

				.links {
					@extend .nlst;
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
					text-align: right;

					li {
						&:after {
							content: "|";
							padding: 0 10px;
						}

						&:last-child {
							&:after {
								content: "";
								padding: 0;
							}
						}
					}
				}

				@media only screen and (max-width : $screen-xs) {
					.links {
						display: block;
						text-align: center;
						margin-top: 20px;

						li {
							text-align: center;

							&:after {
								content: "";
								padding: 0;
							}
						}
					}
				}
			}
		}




		@media only screen and (max-width : $screen-sm-max) {
			.container {
				display: block;

				.col {
					padding-top: 0;
					margin: 0 auto 20px auto;
					max-width: 300px;

					&:last-child {
						margin-bottom: 0;
					}

					.logo {
						position: relative;
						display: block;
						margin: 0 auto 30px auto;
					}

					.subscribe-now {

					}

					&:last-child {
						margin: 0 auto;
					}

				}
			}

			&.bg-blue {
				padding: 15px 0 0 0;
				text-align: center;

				ul.locations {
					display: flex;
					flex-direction: row;
					justify-content: center;
					flex-wrap: wrap;

					li {
						margin: 0 10px;
					}
				}

				ul.latest-news {
					li {
						.pub-date {
							@include font-size(12px);
						}
					}
				}
			}

			&.bg-darkblue {
				.container {
					text-align: center;
					padding: 5px 10px;

					.links {
						justify-content: center;
					}
				}
			}
		}
	}
}