#sponsors {
    text-align: center;

    .anbi {
        @include font-size(18px);
        @extend .font-medium;
        text-align: left;
        display: flex;
        flex-direction: row;
        max-width: 400px;
        align-items: center;
        margin: 20px auto 30px;

        @media only screen and (max-width: $screen-xs-max) and (orientation: portrait) {
            margin: 20px 10px 30px;
        }

        img {
            margin-right: 30px;
            width: auto;
            height: 70px;
        }

        span {
            display: block;
            max-width: 280px;
        }
    }

    ul#lightSlider {
        list-style: none outside none;
        padding-left: 0;
        margin-bottom: 0;

        .panel {
            padding: 0 0 30px 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            min-height: 290px;

            .sponsor {
                width: 160px;
                height: 100px;
                padding: 10px;
                background: $ws-bg-white;
                margin: 15px;
                border: 1px solid $ws-border-gray;
                border-radius: 5px;
                background-size: 80% auto;
                background-repeat: no-repeat;
                background-position: center center;
                box-shadow: none;
                transition: box-shadow 0.2s ease;
                display: flex;
                flex-shrink: 0;

                @media only screen and (max-width: $screen-md) {
                    width: 100px;
                    height: 70px;
                }

                @media only screen and (max-width: $screen-sm) {
                    width: calc((100% / 4) - 20px);
                    height: 100px;
                    margin: 10px;
                }

                @media only screen and (max-width: $screen-xs-max) and (orientation: portrait) {
                    width: calc((100% / 2) - 20px);
                }

                &:hover {
                    box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);
                }
            }
        }
    }

    .lSSlideOuter .lSPager.lSpg > li {
        padding: 0 8px;

        a {
            border: 1px solid $ws-bg-lightblue;
            background-color: $ws-bg-white;
            height: 20px;
            width: 20px;
            transition: all .2s linear 0s
        }

        &:hover, &.active {
            a {
                background-color: $ws-bg-lightblue;
            }
        }

    }

    .list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        display: none;

    }
}