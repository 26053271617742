@font-face {
    font-family: 'Adagio_SlabMedium';
    src: url('../fonts/Adagio_Slab-Medium.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Adagio_Slab-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Adagio_Slab-Medium.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Adagio_Slab-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Adagio_Slab-Medium.svg#Adagio_Slab-Medium') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Adagio_SlabSemiBold';
    src: url('../fonts/Adagio_Slab-SemiBold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Adagio_Slab-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Adagio_Slab-SemiBold.woff') format('woff'), /* Modern Browsers */
    url('../fonts/Adagio_Slab-SemiBold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/Adagio_Slab-SemiBold.svg#Adagio_Slab-SemiBold') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

.font-light {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

.font-book {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

.font-medium {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.font-bold {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.font-header-medium {
    font-family: "Adagio_SlabMedium", Georgia;
}

.font-header-semibold {
    font-family: "Adagio_SlabSemiBold", Georgia;
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
    text-transform: uppercase;
    font-weight: normal;
    margin: 0;

    @media only screen and (max-width: $screen-xs) {
        word-break: break-word;
    }
}

h1, .h1 {
    @include font-size(36px);
    @extend .font-header-semibold;
    line-height: 100%;

    @media only screen and (max-width: $screen-xs) {
        @include font-size(30px);
    }
}

h2, .h2 {
    @include font-size(36px);
    @extend .font-header-semibold;
    line-height: 100%;

    @media only screen and (max-width: $screen-xs) {
        @include font-size(30px);
    }
}

h3, .h3 {
    @include font-size(20px);
    @extend .font-header-semibold;
}

h4, .h4 {
    @include font-size(24px);
    @extend .font-header-semibold;
}

strong, b {
    @extend .font-bold;
}

p {
    line-height: 200%;
}


h1, h2, h3, h4, h5, p, span {
    &.ws-txt-lightblue, &.lightblue {
        color: $ws-txt-lightblue;
    }
}