.hero {
    min-height: 300px;
    margin-top: 79px;
    height: 300px;
    color: $ws-txt-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: $ws-bg-red;
    background-size: cover;
    background-position: center center;
    background-color: $ws-bg-red;
    background-blend-mode: multiply;

    .container {
        text-align: center;
        position: relative;
        z-index: 2;
        h1 {
            @include font-size(36px);
            text-shadow: 0px 0px 30px rgba(0, 0, 0, 1);

            @media only screen and (max-width: $screen-xxs) {
                word-break: break-word;
            }
        }
    }
}

.no-backgroundblendmode {
    .hero {
        &:before {
            content: "";
            height: 100%;
            width: 100%;
            background: rgba($ws-bg-red, 0.6);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
        }
    }
}