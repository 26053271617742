.wrapper.header-home {
    background: $ws-bg-red;
    margin-top: 79px;
    height: 412px;
    position: relative;

    .bow {
        width: 100%;
        background-image: url('../images/bow.svg');
        background-size: auto 184px;
        background-position: bottom center;
        background-repeat: repeat-x;
        height: 184px;
        position: absolute;
        bottom: -2px;
        left: 0;
        z-index: 3;
    }

    .banner {
        position: relative;
        height: 412px;
        margin-bottom: 50px;
        background-color: $ws-bg-red;
        background-size: cover;
        background-position: center center;
        background-color: $ws-bg-red;
        background-blend-mode: multiply;

        .shader {
            display: none;
            width: 0;
            height: 0;
        }

        &:before, &:after {
            content: "";
            height: 100%;
            width: 150px;
            background-image: linear-gradient(to right, rgba($ws-bg-red, 1),rgba($ws-bg-red, 0));
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
        }

        &:after {
            background-image: linear-gradient(to right, rgba($ws-bg-red, 0),rgba($ws-bg-red, 1));
            left: unset;
            right: 0;
        }

        .toppers-logo {
            height: 320px;
            position: absolute;
            top: -1px;
            left: 60px;
            z-index: 4;
        }

        .main-logo {
            position: absolute;
            bottom: -50px;
            right: 0;
            height: 170px;
            z-index: 3;
        }

        .bullet {
            position: absolute;
            z-index: 4;
            background-color: $ws-darkblue;
            border: 5px solid $ws-white;
            border-radius: 100%;
            display: block;
            text-align: center;
            height: 70px;
            width: 70px;
            bottom: 100px;
            left: 60%;
            text-decoration: none;

            @media only screen and (min-width: $screen-lg) {
                left: 64%;
                bottom: 116px;
            }

            &.large {
                height: 170px;
                width: 170px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                bottom: -30px;
                left: 32%;
                transition: background 0.2s ease;

                @media only screen and (min-width: $screen-lg) {
                    left: 35%;
                }

                span {
                    width: 100%;
                    color: $ws-txt-white;
                    @extend .font-header-semibold;
                    @include font-size(20px);
                    text-transform: uppercase;
                    line-height: 100%;
                    position: relative;
                    display: inline-block;
                }

                &:hover {
                    background-color: $ws-bg-blue;
                    text-decoration: none;
                }
            }
        }
    }

    @media only screen and (max-width: $screen-lg) {
        .banner {
            height: 412px;
        }
    }

    @media only screen and (max-width: $screen-sm) {
        height: 270px;

        .bow {
            border: 0px solid orange;
            height: 80px;
            background-size: auto 80px;
        }

        .banner {
            height: 270px;
            margin-bottom: 35px;
            width: 100%;

            .toppers-logo {
                height: 150px;
                top: 0;
                left: 20px;
            }

            .main-logo {
                bottom: -40px;
                right: 15px;
                height: 100px;
                z-index: 3;
            }

            .bullet {
                bottom: 20px;
                left: 50%;

                &.large {
                    height: 120px;
                    width: 120px;
                    bottom: 0;
                    left: 12%;

                    span {
                        @include font-size(14px);
                    }
                }
            }
        }
    }


    @media only screen and (max-width: $screen-xs-max) and (orientation: portrait){
        height: 250px;

        .bow {
            height: 80px;
            background-size: auto 80px;
        }

        .banner {
            height: 250px;
            margin-bottom: 85px;

            .main-logo {
                bottom: -80px;
                right: 10px;
                height: 90px;
                z-index: 3;
            }

            .bullet {
                display: none;

                &.large {
                    span {
                        @include font-size(14px);
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $screen-xs-max) and (orientation: landscape){
        height: 250px;

        .bow {
            height: 80px;
            background-size: auto 80px;
        }

        .banner {
            height: 250px;

            .main-logo {
                bottom: -40px;
                right: 10px;
                height: 90px;
                z-index: 3;
            }

            .bullet {
                &.large {
                    span {
                        @include font-size(14px);
                    }
                }
            }
        }
    }

    // iphone 5 and se
    @media only screen and (max-height: $screen-xxs) and (orientation: landscape) {
        .banner {
            .bullet {
                display: none;
            }

            .main-logo {
                bottom: -50px;
            }
        }
    }

    @media only screen and (max-width: $screen-xxs) and (orientation: portrait){
        .banner {
            .bullet {
                display: none;

                &.large {
                    bottom: 30px;
                    right: 10px;
                    left: initial;
                }
            }

            .main-logo {
                bottom: -70px;
            }
        }
    }

}

.no-backgroundblendmode {
    .wrapper.header-home {
        background: $ws-bg-red;
        margin-top: 79px;
        height: 414px;

        .banner {
            position: relative;

            .shader {
                display: block;
                height: 100%;
                width: 20%;
                z-index: 2;
                position: absolute;
                top: 0;

                &.left {
                    background-image: linear-gradient(to right, rgba($ws-bg-red, 1),rgba($ws-bg-red, 0));
                    left: 0;
                }

                &.right {
                    background-image: linear-gradient(to right, rgba($ws-bg-red, 0),rgba($ws-bg-red, 1));
                    right: 0;
                }
            }

            &:before {
                content: "";
                height: 100%;
                width: 100%;
                background-image: none;
                background-color: rgba($ws-bg-red, 0.6);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
            }

            &:after {
                display: none;
            }
        }

        @media only screen and (max-width: $screen-lg) {
            .banner {
                height: 412px;
            }
        }

        @media only screen and (max-width: $screen-sm) {
            height: 270px;

            .bow {
                border: 0px solid orange;
                height: 80px;
                background-size: auto 80px;
            }

            .banner {
                height: 270px;
                margin-bottom: 35px;
                width: 100%;
            }
        }

        @media only screen and (max-width: $screen-xs-max) and (orientation: landscape) {
            height: 250px;

            .bow {
                height: 80px;
                background-size: auto 80px;
            }

            .banner {
                height: 250px;
            }
        }
    }
}