nav.navigation {
    @include font-size(18px);
    @extend .font-book;
    flex: 0 1 auto;

    ul {
        @extend .nlst;
        margin: 0;
        padding: 0;

        li {
            a {
                color: $ws-txt-red;
                text-decoration: none;
                display: inline-block;

                &.active, &:hover {
                    color: $ws-txt-lightblue;
                    text-decoration: none;
                }

                .wrapper {
                    border: 1px solid red;
                }
            }

            &:hover {
                a {
                    color: $ws-txt-lightblue;
                }
            }
        }
    }

    // mobile state
    @media only screen and (max-width: $screen-sm) {
        position: fixed;
        background: $ws-bg-white;
        width: 90%;
        min-height: 100%;
        height: 100%;
        z-index: 9998;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition: transform 0.2s ease;
        overflow-y: auto;

        > ul {
            margin-top: 70px !important;
        }

        ul {
            background-color: transparent;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            li {
                border-bottom: 1px solid lighten($ws-border-gray, 8%);
                width: 100%;
                position: relative;

                &:last-child {
                    border-bottom: 0;
                }

                a {
                    padding: 10px;
                    color: $ws-txt-red;
                    text-decoration: none;
                    display: block;
                    width: calc(100% - 50px);
                }

                &.search {
                    position: relative;

                    &.active, &:hover {
                        a {
                            color: $ws-txt-lightblue;
                        }
                    }
                }

                &.haschildren {
                    .toggle-expand {
                        cursor: pointer;
                        font-size: inherit;
                        padding: 0 5px;
                        background: transparent;
                        border: 0;
                        display: inline-block;
                        outline: none;
                        color: $ws-border-gray;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        height: 50px;
                        width: 80px;
                        text-align: right;

                        i {
                            font-size: 30px;
                            margin-right: 10px;
                        }
                    }
                }

                .wrapper {

                    display: block;
                }

                ul {
                    position: relative;
                    background-color: $ws-bg-lightblue;
                    opacity: 0;
                    visibility: hidden;
                    height: 0;
                    width: 0;
                    transition: opacity 0.2s ease;
                    display: block;

                    li {
                        width: 100%;
                        border-color: lighten($ws-bg-lightblue, 5%);
                        display: block;
                    }

                    a {
                        transform: translateX(-10px);
                        color: white;
                        opacity: 0;
                        display: inline-block;
                        width: 100%;
                        transition: all 0.2s ease-in-out;
                        padding-left: 25px;
                    }

                    &.expanded {
                        opacity: 1;
                        visibility: visible;
                        height: auto;
                        width: auto;

                        a {
                            opacity: 1;
                            visibility: visible;
                            color: white;
                            transform: translateX(0px);
                        }
                    }
                }
            }
        }
    }

    // desktop;
    @media (min-width: $screen-sm + 1) {
        justify-content: center;
        width: 100%;
        height: auto;

        > ul {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            > li {
                flex-direction: row;

                a {
                    padding: 30px 15px;
                    position: relative;
                }

                &.search {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    &.active, &:hover {
                        a {
                            color: $ws-txt-lightblue;
                        }
                    }
                }

                &.search {
                    margin-left: 40px;
                }

                &.haschildren {
                    .toggle-expand {
                        display: none;
                    }

                    > a {
                        &:after {
                            content: "";
                            height: 12px;
                            width: 12px;
                            background: white;
                            transform: rotate(45deg);
                            position: absolute;
                            left: calc(50% - 4px);
                            opacity: 0;
                            bottom: 0px;
                            visibility: hidden;
                            transition: bottom 0.4s ease;
                            z-index: 100;
                        }
                    }

                    &:hover {
                        > a {
                            &:after {
                                opacity: 1 !important;
                                visibility: visible !important;
                                bottom: -4px;
                            }
                        }
                    }
                }

                // subnav
                .wrapper {
                    width: 100%;
                    height: auto;
                    position: absolute;
                    left: 0px;
                    opacity: 0;
                    visibility: hidden;
                    height: 0;
                    transform: translateY(0px);
                    transition: background 0.4s ease;
                    box-shadow: 0 4px 10px 0px rgba(0,0,0,.12);

                    ul {
                        background-color: transparent;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-start;
                        margin-left: auto;
                        margin-right: auto;
                        flex-wrap: wrap;
                        padding: 12px 0;


                        li {
                            flex-basis: 25%;

                            a {
                                opacity: 1;
                                transform: translateY(-6px);
                                transition: transform 0.4s ease-out, color 0.2s ease;
                                padding: 5px 0;

                                &.active, &:hover {
                                    color: $ws-txt-lightblue2 !important;
                                }

                            }
                        }
                    }
                }

                &:hover {
                    .wrapper {
                        opacity: 1;
                        visibility: visible;
                        background-color: #95C6EA;
                        height: auto;
                        z-index: 99;

                        > ul {
                            li {
                                a {
                                    color: white;
                                    transform: translateY(0);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}