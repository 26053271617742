// main colors
$ws-white:				#ffffff;
$ws-red:				#e20816;
$ws-lightblue:			#94c6ec;
$ws-darkblue:			#265caa;
$ws-green:              #50ae31;


// background colors
$ws-bg-lightestblue:	#f4f9fd;
$ws-bg-white:			$ws-white;
$ws-bg-red:				$ws-red;
$ws-bg-lightblue:		$ws-lightblue;
$ws-bg-blue:			#364871;			// footer top
$ws-bg-darkblue:		#233254;			// footer bottom


// logo colors
$ws-logo-yellow:		#fcdf43;
$ws-logo-green:			$ws-green;
$ws-logo-darkblue:		#2953a0;


// text colors
$ws-txt-red:			$ws-red;
$ws-txt-white:			$ws-white;
$ws-txt-gray:			#535353;
$ws-txt-black:			#464646;
$ws-txt-lightblue:		$ws-lightblue;
$ws-txt-lightblue2:     #cae3f6;            // sub navigation hover


// other colors
$ws-border-lightgray:   #d4d4d4;
$ws-border-gray:        #cccccc;
$ws-border-lightblue:   #9ba4b8;