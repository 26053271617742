$section-padding: 		60px;
$element-padding: 		30px;
$border-radius: 		3px;

$transition-speed:		0.2s;

$input-height:			40px;

$screen-xxs:			320px !default;
$screen-xs:				480px !default;
$screen-sm:				768px !default;
$screen-md:				992px !default;
$screen-lg:				1200px !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm - 1) !default;
$screen-sm-max:              ($screen-md - 1) !default;
$screen-md-max:              ($screen-lg - 1) !default;
