ul {
	margin: 0;
	
	&.nlst {
		list-style-type: none;
		padding: 0;
	}

	li {

	}
}