section.locations {
    padding-top: 0;

    .locations-list {

        &.home {
            @extend .nlst;
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;

            .location {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 40px 40px 0 0;

                &:last-child {
                    margin-right: 0;
                }

                .location-thumb {
                    height: 160px;
                    width: 160px;
                    border-radius: 100%;
                    margin-bottom: 12px;
                    transition: box-shadow 0.2s ease;
                    border: none;

                    &:hover {
                        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
                    }
                }

                .btn {
                    text-align: center;
                }

                @media only screen and (max-height: $screen-xxs) and (orientation: landscape) {
                    margin: 30px 30px 0 0;
                }

            }

            @media only screen and (max-width: $screen-xs) and (orientation: portrait) {
                flex-direction: column;

                .location {
                    margin: 0 0 12px 0;

                    .location-thumb {
                        display: none;
                    }
                }
            }

        }
    }

    @media only screen and (max-width: $screen-xs),
    only screen and (max-height: $screen-xxs) and (orientation: landscape) {
        padding-top: 30px;
    }
}