.ws-logo {
  @extend .font-header-semibold;
  color: $ws-txt-white;

  span {
    display: block;
    text-align: right;
    text-transform: uppercase;
    line-height: 1.05em;

    &.st {
      font-size: 0.92em;
    }

    &.ws {

    }

    &.toz {
      font-size: 0.87em;
    }
  }

}