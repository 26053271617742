@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?salsl0');
    src: url('../fonts/icomoon.eot?salsl0#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?salsl0') format('truetype'),
    url('../fonts/icomoon.woff?salsl0') format('woff'),
    url('../fonts/icomoon.svg?salsl0#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
    content: "\f002";
}
.icon-check:before {
    content: "\f00c";
}
.icon-close:before {
    content: "\f00d";
}
.icon-remove:before {
    content: "\f00d";
}
.icon-times:before {
    content: "\f00d";
}
.icon-phone:before {
    content: "\f095";
}
.icon-arrow-circle-left:before {
    content: "\f0a8";
}
.icon-arrow-circle-right:before {
    content: "\f0a9";
}
.icon-envelope:before {
    content: "\f0e0";
}
.icon-angle-up:before {
    content: "\f106";
}
.icon-angle-down:before {
    content: "\f107";
}
.icon-info:before {
    content: "\f129";
}